import { Container } from "react-bootstrap"
import Section from "../components/Section"
import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const PersonalDataPolitics = () => {
  return (
    <Layout footerHalf={false}>
      <SEO title="Données personnelles et cookies" />
      <Section className="pt-5 pb-2" css={{ marginTop: "60px" }}>
        <Container className="content mt-3">
          <h1>Données personnelles et cookies</h1>
        </Container>
      </Section>
      <Section className="pt-0 content">
        <Container>
          <h2 className="pt-3">
            Politique d'utilisation et de conservation des données personnelles
          </h2>
          <p>
            Le groupe ELOIS accorde une grande importance à la protection de la
            vie privée et des données personnelles qui sont collectées et
            utilisées dans le cadre de ses activités et services. La présente
            politique décrit les règles que le groupe ELOIS a définies, et
            applique en matière de protection des données personnelles, dans le
            respect des réglementations en vigueur, et notamment :
          </p>
          <ul>
            <li>
              La loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux
              fichiers et aux libertés modifiée (dite « loi Informatique et
              Libertés »)
            </li>
            <li>
              Le Règlement (UE) 2016/679 du Parlement européen et du Conseil du
              27 avril 2016 (dit « Règlement Général sur la Protection des
              Données »)
            </li>
          </ul>

          <h2 className="pt-3">Définitions</h2>
          <p>
            <span className="font-weight-bold">Client :</span> Tout individu qui
            rentre en contact avec Simulassur.fr dans le cadre de son changement
            d’assurance emprunteur.
            <br />
            <span className="font-weight-bold">Consommateur :</span> le client
            ou prospect du commerçant.
            <br />
            <span className="font-weight-bold">
              Données à caractère personnel ou Données personnelles :
            </span>
            “Toute information se rapportant à une personne physique identifiée
            ou identifiable (ci-après dénommée « personne concernée »); est
            réputée être une « personne physique identifiable » une personne
            physique qui peut être identifiée, directement ou indirectement,
            notamment par référence à un identifiant, tel qu'un nom, un numéro
            d'identification, des données de localisation, un identifiant en
            ligne, ou à un ou plusieurs éléments spécifiques propres à son
            identité physique, physiologique, génétique, psychique, économique,
            culturelle ou sociale” (article 4 du RGPD).
            <br />
            <span className="font-weight-bold">
              Responsable du traitement :
            </span>
            “La personne physique ou morale, l'autorité publique, le service ou
            un autre organisme qui, seul ou conjointement avec d'autres,
            détermine les finalités et les moyens du traitement; lorsque les
            finalités et les moyens de ce traitement sont déterminés par le
            droit de l'Union ou le droit d'un État membre, le responsable du
            traitement peut être désigné ou les critères spécifiques applicables
            à sa désignation peuvent être prévus par le droit de l'Union ou par
            le droit d'un État membre” (article 4 du RGPD).
            <br />
            <span className="font-weight-bold">Sous-traitant :</span> “La
            personne physique ou morale, l'autorité publique, le service ou un
            autre organisme qui traite des données à caractère personnel pour le
            compte du responsable du traitement” (article 4 du RGPD).
          </p>

          <h2>Responsables de traitement</h2>
          <p>
            ELOIS est le Responsable du traitement des données personnelles des
            clients, recueillies dans le cadre de la relation contractuelle qui
            lie ELOIS au Client.
          </p>
          <p>ELOIS</p>
          <p>SAS au capital de 100 000€</p>
          <p>23 rue du Départ – 75014 Paris</p>
          <p>RCS Paris 483 413 258</p>
          <h2>Données personnelles des clients de Simulassur.fr</h2>
          <p>
            Vous pouvez visiter nos sites sur Internet
            <span style={{ textDecoration: "underline" }} className="px-2">
              https://www.simulassur.fr/
            </span>
            sans avoir à décliner votre identité ou à fournir des informations
            personnelles vous concernant.
          </p>
          <p>
            Cependant, nous pouvons parfois vous demander des informations
            telles que vos coordonnées de contact afin de pouvoir vous
            recontacter suite à une simulation effectuée sur notre site.
          </p>
          <p>
            Dans le cadre de ces différentes demandes, Simulassur.fr collecte
            les catégories de données suivantes :
          </p>
          <ul>
            <li>
              Données d’identification : données d’état civil, adresse postale,
              courriel, numéro de téléphone, numéro client
            </li>
            <li>
              Données d’usage : Garanties souscrites, Montant de l’emprunt, et
              toutes informations relatives au crédit pour lequel vous demandez
              le changement de l’assurance emprunteur.
            </li>
            <li>
              Données de suivi de la relation client : demandes d’assistance,
              correspondance avec le client, échanges avec les intervenants liés
              aux opérations de crédits.
            </li>
          </ul>

          <h2>Informations traitées</h2>
          <p>
            Les données personnelles collectées par le groupe ELOIS sont
            uniquement les données nécessaires à la réalisation des finalités de
            traitement décrites ci-après. Le groupe ELOIS s’engage à ne pas
            collecter plus de données que nécessaire.
          </p>
          <p>
            De par la nature même de ses activités, le groupe ELOIS est appelé à
            traiter de multiples données personnelles, y compris des données
            relevant de catégories particulières comme les données médicales.
            Sauf consigne formelle de votre part, ces dernières ne seront
            traitées que par la cellule médecin conseil, dont le personnel est
            spécifiquement formé et habilité, dans le respect des dispositions
            du Code de la Santé Publique.
          </p>
          <p>
            La collecte de ces informations personnelles vise uniquement au
            ciblage d’une offre adaptée à votre profil (âge, profession, etc).
          </p>
          <p>
            Les données médicales recueillies dans le cadre de la souscription à
            votre contrat font l’objet d’un traitement dont le responsable de
            traitement est l’assureur à l’exception des opérations dont le
            responsable de traitement et le périmètre sont définis à la notice
            d’information.
          </p>
          <p>
            Le traitement des données a pour finalité de satisfaire votre
            demande et de permettre la réalisation de mesures précontractuelles,
            d’actes de souscription, de gestion et d’exécution ultérieure. À ce
            titre, elles pourront être utilisées à des fins de recouvrement,
            d’exercice des recours et de gestion des réclamations et
            contentieux, d’examen, d’appréciation, de contrôle, de surveillance
            du risque et à des fins d’études statistiques et actuarielles. Ces
            traitements ont comme base juridique l’exécution du contrat ou de
            mesures précontractuelles.
          </p>
          <p>
            Les informations relatives aux données de santé sont traitées dans
            le cadre de la passation, la gestion et l’exécution du contrat
            (finalités mentionnées ci-dessus). Ce traitement a pour base
            juridique le consentement.
          </p>
          <p>
            En remplissant le questionnaire médical, vous acceptez le traitement
            des données de santé dans ce cadre et vous acceptez le traitement
            des données de santé qui pourraient vous être demandées
            ultérieurement pour ces mêmes finalités.
          </p>
          <p>
            Nos « intérêts légitimes » sont les intérêts propres à la conduite
            de nos activités. Avant la mise en œuvre d’un traitement ayant pour
            fondement ces intérêts légitimes, nous veillons attentivement à
            l’équilibre entre ces intérêts et vos droits fondamentaux, notamment
            en évaluant les impacts potentiels vous concernant. Nous n’utilisons
            pas vos données personnelles dans le cadre d’activités de traitement
            pour lesquelles vos droits fondamentaux prévalent sur nos intérêts
            légitimes (sauf consentement de votre part ou disposition légale
            particulière).
          </p>
          <p>
            Toute finalité de traitement soumise à votre consentement est
            présentée en conséquence et de manière explicite au moment de la
            collecte des données personnelles afférentes. Le retrait de ce
            consentement peut s’effectuer à tout moment sans que cela ne remette
            en cause la licéité du traitement fondé sur ce dernier et effectué
            antérieurement au retrait.
          </p>
          <h2>Destinataires de vos données personnelles</h2>
          <p>
            Au sein du groupe ELOIS, l’accès à vos données personnelles est
            strictement limité aux personnes habilitées qui, par leurs
            fonctions, sont amenées à traiter ces données en vue de la
            réalisation des finalités prévues.
          </p>
          <p>
            Ces données personnelles pourront également être transmises aux
            assureurs, réassureurs et gestionnaires de vos contrats d’assurance
            aux fins d’exécution de ces contrats.
          </p>
          <p>
            Dans le cas où vous auriez donné votre consentement à être contacté
            par nos partenaires commerciaux, vos données personnelles pourront
            également leur être transmises.
          </p>
          <h2>Conservation de vos données personnelles</h2>
          <p>
            Les Données personnelles sont stockées simultanément sur les
            serveurs physiques de Simulassur.fr et les serveurs externes de chez
            OVH. Ce stockage des données suppose de respecter leur
            confidentialité et de veiller à leur sécurité. Les Données hébergées
            par les serveurs de Simulassur.fr restent la propriété du Client.
            Simulassur.fr est le Responsable de traitement.
          </p>
          <p>
            OVH est Sous-traitant en ce qu’il ne fait qu’héberger les données
            stockées.
          </p>
          <p>
            Dans le cas où Simulassur.fr confie les activités de traitement de
            données à des sous-traitants pour assurer les prestations, ces
            derniers seront notamment choisis pour les garanties suffisantes
            quant à la mise en œuvre des mesures techniques et
            organisationnelles appropriées, notamment en termes de fiabilité et
            de mesures de sécurité.
          </p>
          <p>
            Simulassur.fr vous informe que vos données sont susceptibles d’être
            transférées hors Union Européenne dans le cadre de l’étude de votre
            demande à des fins de gestion commerciale pour permettre la mise en
            œuvre de ses prérogatives.
          </p>
          <p>
            Le transfert peut concerner tout ou partie des informations
            renseignées dans les formulaires de demande. Les destinataires
            seront nos sous-traitants et nos partenaires, lesquels peuvent avoir
            des centres d’appels ou des prestataires basés à l’étranger. En cas
            de transfert hors de l'Union Européenne de données personnelles, des
            garanties sont prises pour assurer un bon niveau de protection de
            ces données. Elles seront uniquement communiquées aux destinataires
            qui ont besoin d’y avoir accès pour la réalisation de leur mission.
            Le transfert est limité aux pays listés par la Commission Européenne
            comme protégeant suffisamment les données ou aux destinataires
            respectant les clauses contractuelles types de la Commission
            Européenne.
          </p>
          <p>
            Les données Clients sont hébergées sur des sites sécurisés. Nos
            partenaires d’hébergement sont :
          </p>
          <ul>
            <li>OVH</li>
            <li>Universign</li>
          </ul>
          <p>
            Les données des Consommateurs, lorsqu’elles sont hébergées par
            Simulassur.fr le sont sur un Serveur physique sécurisé interne.
          </p>
          <h2>Durée de conservation des données</h2>
          <p>
            Simulassur.fr conserve vos données personnelles pendant la durée
            strictement nécessaire aux finalités pour lesquelles elles ont été
            collectées et ce, conformément à la réglementation applicable et
            dans le respect de l’exercice de vos droits. Nous conservons
            également certaines de vos données à caractère personnel, avec un
            accès restreint, afin de remplir nos obligations légales ou
            réglementaires ou à des fins statistiques et ce, pour les durées
            prévues par les recommandations de la CNIL ou par la loi.
          </p>
          <p>Ainsi vos données sont conservées de la manière suivante :</p>
          <ul>
            <li>
              <span className="font-weight-bold"> 3 ans </span> en l’absence de
              signature de contrat d’assurance par l’intermédiaire de
              Simulassur.fr
            </li>
            <li>
              <span className="font-weight-bold"> 5 ans </span> à compter de la
              résiliation d’un contrat d’assurance conclu par l’intermédiaire de
              Simulassur.fr
            </li>
            <li>
              <span className="font-weight-bold"> 5 ans </span> à compter de la
              fin de la gestion d’un sinistre d’un contrat d’assurance souscrit
              par l’intermédiaire de Simulassur.fr
            </li>
          </ul>

          <h2>Sécurité des données</h2>
          <p>
            Simulassur.fr met en œuvre les actions nécessaires afin de protéger
            les données personnelles qu’elle traite. Les données sont traitées
            de façon électronique et/ou manuelle et en tout état de cause, de
            façon à ce que leur sécurité, protection et confidentialité soient
            assurées au regard de leur niveau de sensibilité, via des mesures
            administratives, techniques et physiques pour prévenir la perte, le
            vol, l’utilisation, la divulgation ou encore la modification
            non-autorisées.
          </p>
          <p>
            Les conversations téléphoniques avec nos conseillers téléphoniques
            peuvent être enregistrées, en vue de l'amélioration de la qualité de
            nos services. Ces informations sont destinées à Simulassur.fr,
            responsable du traitement et sont nécessaires à la gestion de votre
            demande. Vous pouvez vous opposer à cet enregistrement en le
            signalant au conseiller.
          </p>
          <h2>Droits et modalités de contact</h2>
          <p>
            Préalablement à la mise en œuvre des traitements de données
            personnelles, et au plus tard lors de la collecte de ces données,
            vous êtes informés :
          </p>
          <ul>
            <li>
              de l’identité et des coordonnées du responsable du traitement ;
            </li>
            <li>
              de la durée de conservation des données collectées ou des critères
              permettant de la déterminer ;
            </li>
            <li>
              des destinataires de ces données ou catégories de destinataires ;
            </li>
            <li>
              Information du traitement des données personnelles hors UE ;
            </li>
            <li>de vos droits.</li>
          </ul>

          <p>
            Conformément à la réglementation en vigueur, vous disposez d’un
            droit d’accès, de rectification et d’effacement de vos données. Vous
            pouvez également vous opposer à leur traitement, obtenir sa
            limitation et la portabilité de vos données, dans la mesure où cela
            est applicable et sous réserve des motifs légitimes pouvant amener
            le groupe ELOIS à ne pas donner suite à votre demande.
          </p>
          <p>
            Par ailleurs, vous pouvez à tout moment demander à ne plus recevoir
            les communications relatives à nos offres, produits et évènements en
            utilisant le lien de désabonnement prévu à cet effet dans chaque
            message que nous vous adressons.
          </p>
          <p>
            Vous disposez également du droit de nous donner des directives
            spécifiques concernant le sort de vos données après votre décès.
            Enfin, vous disposez du droit d’introduire une réclamation auprès de
            la CNIL.
          </p>
          <p>
            Pour exercer ces droits, vous pouvez adresser votre demande à notre
            DPO - Déléguée à la Protection des Données, en contactant Aurore
            Amand à cette adresse : dpo@simulassur.fr ou bien à :
          </p>

          <p>
            ELOIS
            <br />
            DPO
            <br />
            14 rue Pierre Gilles de Gennes
            <br />
            76130 Mont Saint Aignan
          </p>

          <h2> Information CNIL</h2>
          <p>
            En cas de réclamation à laquelle Simulassur.fr ne vous aurait pas
            donné de réponse satisfaisante, vous pouvez vous adresser à la
            Commission Nationale de l’Informatique et des Libertés (CNIL) en
            charge du respect des obligations en matière de données
            personnelles.
          </p>
          <ul>
            <li>Sur le site web de la CNIL:</li>
            <ul>
              <li>
                dans certains cas déterminés, par le téléservice de plainte en
                ligne;
              </li>
              <li>
                dans les "autres cas" non prévus par le téléservice, par le
                service "Besoin d'aide", qui vous permettra d'accéder au
                formulaire de plainte au bas d'une réponse à une question.
              </li>
            </ul>
            <li>
              Par courrier postal en écrivant à : CNIL - Service des Plaintes -
              3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.
            </li>
          </ul>
          <br />
          <h2>Politique d'utilisation des cookies </h2>
          <h3>Qu’est-ce qu’un cookie ?</h3>
          <p>
            Les cookies sont de petits fichiers transférés sur votre ordinateur
            par un site web, qui collectent des données sur votre navigation.
          </p>
          <p>
            C’est grâce aux cookies qu’une boutique en ligne conserve le contenu
            de votre panier alors que vous continuez à parcourir le site, par
            exemple. C’est aussi grâce à eux que vous n’avez pas besoin de
            redonner votre identifiant et votre mot de passe sans arrêt lorsque
            vous naviguez sur certains sites. La durée de vie des cookies peut
            être très courte ou durer plusieurs mois.
          </p>
          <p>
            Certains cookies peuvent apparaître intrusifs aux yeux des
            internautes, c’est pourquoi la loi encadre strictement leur usage.
            Lorsque les cookies utilisés correspondent à ceux visés par la
            réglementation, le site web qui les utilise doit en informer ses
            utilisateurs en précisant leur finalité. Il doit également
            recueillir leur consentement pour utiliser ces cookies.
          </p>
          <p>
            Il est possible de bloquer l’installation des cookies et de
            supprimer ceux déjà installés (voir plus bas).
          </p>
          <h3>Quels cookies utilisons-nous ?</h3>
          <p className="font-weight-bold">Cookies Simulassur.fr</p>
          <p>
            Les cookies présents sur le site Simulassur sont utilisés pour :
          </p>
          <ul>
            <li>De mesurer et d’analyser le trafic sur notre site</li>
            <li>De proposer une expérience utilisateur optimale</li>
            <li>D’effectuer des campagnes publicitaires</li>
          </ul>
          <p className="font-weight-bold">Cookies tiers</p>
          <p className="font-weight-semi-bold">Matomo :</p>
          <p>
            Matomo nous permet de collecter des informations sur votre
            navigation : votre parcours sur le site, le temps passé sur nos
            pages, ce sur quoi vous cliquez, etc. Ces données nous permettent de
            mieux comprendre le comportement et les attentes de nos utilisateurs
            afin d’améliorer nos services.
          </p>
          <p>
            Aucune donnée personnelle (comme votre nom) n’est collectée par
            Matomo. Pour en savoir plus sur les cookies utilisés par Matomo :
            <a
              href="
            https://fr.matomo.org/privacy-policy/
            "
            style={{textDecoration:'underline'}}
            target="_blank"
            >
              https://fr.matomo.org/privacy-policy/
            </a>
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default PersonalDataPolitics
